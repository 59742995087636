var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('a-form',{attrs:{"form":_vm.form},on:{"submit":_vm.submitForm}},[_c('a-row',{staticClass:"files",attrs:{"gutter":20}},[_c('a-col',{attrs:{"span":24}},[_c('a-row',{attrs:{"gutter":20}},[_c('a-col',{attrs:{"span":6}},[_c('div',{staticClass:"label mb-10"},[_vm._v("Contrato assinado")]),_vm._v(" "),(!_vm.doc)?_c('div',[_c('a-upload',{attrs:{"multiple":false,"action":`https://api.viajar.tur.br/v1/media/upload-to-s3?module=opportunities&id=${_vm.tempOpportunity.id}&sub-folder=signed-contract`,"headers":{
                  authorization: `Bearer ${this.$store.state.token}`,
                },"disabled":!_vm.edit},on:{"change":_vm.uploadedFile},model:{value:(_vm.doc),callback:function ($$v) {_vm.doc=$$v},expression:"doc"}},[_c('a-button',{attrs:{"type":"dashed","size":"large","disabled":!_vm.edit}},[_c('a-icon',{attrs:{"type":"file-protect"}})],1)],1)],1):_vm._e(),_vm._v(" "),(_vm.doc)?_c('div',{staticClass:"file-link relative"},[(_vm.doc)?_c('a',{attrs:{"href":_vm.doc,"target":"_blank"}},[(_vm.checkFileType(_vm.doc))?_c('img',{staticStyle:{"max-width":"160px"},attrs:{"src":_vm.doc}}):_c('a-icon',{staticClass:"cblue",staticStyle:{"font-size":"38px"},attrs:{"type":"idcard"}})],1):_vm._e(),_vm._v(" "),(_vm.doc && _vm.edit)?_c('a-icon',{staticClass:"close-circle",attrs:{"type":"close-circle"},on:{"click":function($event){return _vm.removefile('signed-contract')}}}):_vm._e()],1):_vm._e()]),_vm._v(" "),_c('a-col',{attrs:{"span":6}},[_c('a-form-item',[_c('div',{staticClass:"label"},[_vm._v("Data de criação")]),_vm._v(" "),_c('a-date-picker',{directives:[{name:"mask",rawName:"v-mask",value:('##/##/####'),expression:"'##/##/####'"},{name:"decorator",rawName:"v-decorator",value:([
                  'opportunity_files_signed_contract_created',
                  {
                    rules: [
                      {
                        required: false,
                        message: 'Obrigatório',
                      },
                    ],
                  },
                ]),expression:"[\n                  'opportunity_files_signed_contract_created',\n                  {\n                    rules: [\n                      {\n                        required: false,\n                        message: 'Obrigatório',\n                      },\n                    ],\n                  },\n                ]"}],staticClass:"travel-input",attrs:{"placeholder":"Selecione","format":"DD/MM/YYYY HH:mm","showToday":false,"allowClear":false,"disabled":true}},[_c('a-icon',{attrs:{"slot":"prefix","type":"field-svg"},slot:"prefix"})],1)],1)],1),_vm._v(" "),_c('a-col',{attrs:{"span":6}},[_c('a-form-item',[_c('div',{staticClass:"label"},[_vm._v("Data de validade")]),_vm._v(" "),_c('a-date-picker',{directives:[{name:"mask",rawName:"v-mask",value:('##/##/####'),expression:"'##/##/####'"},{name:"decorator",rawName:"v-decorator",value:([
                  'opportunity_files_signed_contract_expires_at',
                  {
                    rules: [
                      {
                        required: false,
                        message: 'Obrigatório',
                      },
                    ],
                  },
                ]),expression:"[\n                  'opportunity_files_signed_contract_expires_at',\n                  {\n                    rules: [\n                      {\n                        required: false,\n                        message: 'Obrigatório',\n                      },\n                    ],\n                  },\n                ]"}],staticClass:"travel-input",attrs:{"placeholder":"Selecione","format":"DD/MM/YYYY","showToday":false,"allowClear":true,"disabled":!_vm.edit}},[_c('a-icon',{attrs:{"slot":"prefix","type":"field-svg"},slot:"prefix"})],1)],1)],1)],1)],1),_vm._v(" "),_c('a-col',{staticClass:"actions",attrs:{"span":24}},[(_vm.edit)?_c('a-button',{staticClass:"ml-10 cancel",attrs:{"type":"primary"},on:{"click":function($event){_vm.edit = false}}},[_c('a-icon',{attrs:{"type":"close"}})],1):_vm._e(),_vm._v(" "),(!_vm.edit)?_c('a-button',{staticClass:"ml-10 edit",attrs:{"type":"primary"},on:{"click":function($event){_vm.edit = true}}},[_c('a-icon',{attrs:{"type":"edit"}})],1):_vm._e(),_vm._v(" "),(_vm.edit)?_c('a-button',{staticClass:"ml-10 save",staticStyle:{"padding":"0px 6px"},attrs:{"html-type":"submit","type":"primary","loading":_vm.loading}},[_c('img',{directives:[{name:"show",rawName:"v-show",value:(!_vm.loading),expression:"!loading"}],attrs:{"src":require("@/assets/images/dashboard/sales/disket.png"),"alt":"save"}})]):_vm._e()],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }